import { Injectable } from '@angular/core';

import * as $ from 'jquery';

import { AppcmsService } from './appcms.service';
import { BasketService } from './basket.service';
import { UserService } from './user.service';
import { EventsService } from './events.service';
import { OrdersService } from './orders.service';
import { CacheService } from './cache.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class WoocommerceextendService {
  
  categoriesByUid: any = window.localStorage.getItem('categoriesByUid')
    ? JSON.parse(window.localStorage.getItem('categoriesByUid'))
    : {}

  products: any = window.localStorage.getItem('products')
    ? JSON.parse(window.localStorage.getItem('products'))
    : null

  productsByCategory: any = window.localStorage.getItem('productsByCategory')
    ? JSON.parse(window.localStorage.getItem('productsByCategory'))
    : {}

  productsByUid: any = window.localStorage.getItem('productsByUid')
    ? JSON.parse(window.localStorage.getItem('productsByUid'))
    : {}

  workload: any

  _deliveryOptions: any

  constructor(
    private AppCMS: AppcmsService,
    private basket: BasketService,
    private cache: CacheService,
    private events: EventsService,
    private ordersService: OrdersService,
    private tools: ToolsService,
    public userService: UserService,
  ) {
    this.events.subscribe(
      'checkout:deliveryOptions:updated',
      (_deliveryOptions: any) => {
        this._deliveryOptions = _deliveryOptions
        if (this.products) {
          this.parseProducts(this.products)
            .then((products: any) => {
              this.products = products
            })
            .catch((error: any) => {
              this.events.publish('error', error)
            })
        }
      },
    )
  }

  async buy(basketStorage: any) {
    let Articles = []
    basketStorage.forEach((Article: any) => {
      Articles.push(this.basket.toBasketItem(Article))
    })

    return this.AppCMS.loadPluginData(
      'woocommerceextend',
      {
        basket: Articles,
        basketInfo: await this.basket.calculateBasketInfo(basketStorage),
        user: this.userService.getUser(),
      },
      ['buy'],
    )
  }

  clearCache(prefetch: boolean = false) {
    this.getCategories()
      .then((categories: any) => {
        if (categories && categories.length) {
          categories.forEach((category: any) => {
            window.localStorage.removeItem(
              'productsByCategory_' +
                category.id +
                '_' +
                this.AppCMS.getApiUrl(),
            )
          })
        }

        window.localStorage.removeItem('products' + this.AppCMS.getApiUrl())
        window.localStorage.removeItem(
          'productsByUid_' + this.AppCMS.getApiUrl(),
        )
        window.localStorage.removeItem(
          'categoriesByUid_' + this.AppCMS.getApiUrl(),
        )
        window.localStorage.removeItem(
          'woocommerceCategories_' + this.AppCMS.getApiUrl(),
        )

        if (prefetch) {
          this.getProducts()
            .then(() => {
              this.getCategories()
                .then((categories: any) => {
                  console.warn('loaded categories', categories)
                })
                .catch((error) => {
                  console.warn('prefetch getCategories error', error)
                })
            })
            .catch((error) => {
              console.warn('prefetch getProducts error', error)
            })
        }
      })
      .catch((error) => {
        console.warn('error', error)
      })
  }

  createCoupon(coupon: any) {
    return this.AppCMS.loadPluginData(
      'woocommerceextend',
      {
        coupon: coupon,
      },
      ['coupons', 'create'],
    )
  }

  filterCategories(categories: productCategory[]) {
    if(categories && categories.length) {
      return this.parseCategories(categories.filter((category: any) => {
        return category.id != 17 && category.id != 18 && category.id != 60
      }));
    }
    return this.parseCategories(categories);
  }

  filterTodayAvailable(products: product[]) {
    return products.filter((product: product) => {
      return product.todayAvailable;
    })
  }

  getCategories(blForceReload: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let key = 'woocommerceCategories_' + this.AppCMS.getApiUrl()
      let categoriesFromCache: cacheItem = await this.cache.get(key, 30 * 60);

      // get cached categories
      if (!blForceReload && categoriesFromCache && categoriesFromCache.data) {
        resolve(this.filterCategories(categoriesFromCache.data));
        // reload if required or no categories set
      } else {
        this.AppCMS.loadPluginData('woocommerceextend', {
          _embed: true,
        }, ['categories'])
          .then((categories: any) => {
            categories = this.filterCategories(categories);
            this.cache.set(key, categories);
            resolve(categories);
          })
          .catch(reject)
      }
    })
  }

  getCategoryByUid(categoryId: number) {
    return new Promise((resolve, reject) => {
      this.getCategories()
        .then((categories) => {
          let cachedCategorySelect = Object.keys(categories).filter(
              (iCategory) => {
                let category = categories[iCategory]
                return category.id == categoryId
              },
            ),
            cachedCategory = cachedCategorySelect[0]
              ? categories[cachedCategorySelect[0]]
              : null

          if (cachedCategory) {
            resolve(cachedCategory)
          } else {
            reject('Kategorie nicht gefunden')
          }
        })
        .catch(reject)
    })
  }

  getCoupons() {
    return this.AppCMS.loadPluginData('woocommerceextend', {}, ['coupons'])
  }

  getCouponByCode(code: string) {
    return this.AppCMS.loadPluginData(
      'woocommerceextend',
      {
        code: code,
      },
      ['coupons', 'validate'],
    )
  }

  getCouponByUid(couponId: number) {
    return this.AppCMS.loadPluginData('woocommerceextend', {}, [
      'coupons',
      couponId,
    ])
  }

  getFullProduct(product: any) {
    delete product.backordered
    delete product.backorders
    delete product.button_text
    delete product.cross_sell_ids
    delete product.date_created
    delete product.date_modified
    delete product.default_attributes
    delete product.delivery_time
    delete product.download_expiry
    delete product.download_limit
    delete product.dimensions
    delete product.grouped_products
    delete product.meta_data
    delete product.menu_order
    delete product.sale_price_regular_label
    delete product.unit_price
    delete product.upsell_ids
    delete product.variations
    delete product.yoast_head

    return product
  }

  getProducts(options: any = {}, blForceReload = false) {
    options.limit = options.limit || 100

    return new Promise(async (resolve, reject) => {
      let key =
        'products_' + JSON.stringify(options) + '_' + this.AppCMS.getApiUrl()
      let productsFromCache: cacheItem = await this.cache.get(key);

      if (!blForceReload && productsFromCache && productsFromCache.data) {
        this.parseProducts(productsFromCache.data, options)
          .then((parsedProducts: product[]) => {
            this.cache.set(key, parsedProducts).then(() => {
              resolve(parsedProducts);
            }).catch(reject);
          })
          .catch(reject)
      } else {
        this.AppCMS.loadPluginData('woocommerceextend', options, ['products'])
          .then((products: product[]) => {
            this.parseProducts(products, options)
              .then((parsedProducts: product[]) => {
                this.cache.set(key, parsedProducts).then(() => {
                  resolve(parsedProducts);
                }).catch(reject);
              })
              .catch(reject)
          })
          .catch(reject)
      }
    })
  }

  getProductsByCategory(iCategoryId: number, blForceReload = false) {
    return new Promise(async (resolve, reject) => {
      let key =
        'productsByCategory_' + iCategoryId + '_' + this.AppCMS.getApiUrl();
      let productsByCategoriesFromCache: cacheItem = await this.cache.get(key, 30 * 60)

      let options = {
        limit: 100,
      }
      if (!blForceReload && productsByCategoriesFromCache && productsByCategoriesFromCache.data) {
        resolve(this.parseProducts(productsByCategoriesFromCache.data))
      } else {
        this.AppCMS.loadPluginData('woocommerceextend', options, [
          'categories',
          iCategoryId,
          'products',
        ])
          .then((products: product[]) => {
            this.parseProducts(products)
              .then((products: product[]) => {
                this.cache.set(key, products);
                resolve(products)
              })
              .catch(reject)
          })
          .catch(reject)
      }
    })
  }

  getProductByUid(productId: number) {
    return new Promise((resolve, reject) => {
      this.getProducts()
        .then((products: product[]) => {
          let cachedProductSelect = Object.keys(products).filter((iProduct) => {
              let product = products[iProduct]
              if (product) {
                return product.id == productId
              }
            }),
            cachedProduct = cachedProductSelect[0]
              ? products[cachedProductSelect[0]]
              : null;

          if (cachedProduct) {
            resolve(cachedProduct);
          } else if (this.productsByUid.hasOwnProperty(productId)) {
            resolve(this.productsByUid[productId]);
          } else {
            this.AppCMS.loadPluginData('woocommerceextend', {}, [
              'products',
              productId,
            ])
              .then((response: any) => {
                this.productsByUid[productId] = this.getFullProduct(response)
                this.cache.set(
                  'productsByUid_' + this.AppCMS.getApiUrl(),
                  this.productsByUid,
                );
                resolve(response);
              })
              .catch((error: any) => {
                console.warn('error', error)
                reject('Produkt nicht gefunden')
              })
          }
        })
        .catch(reject)
    })
  }

  getProductVariations(productId: number, iDelay: number = 0) {
    return new Promise(async (resolve, reject) => {
      let key =
        'productVariationsByUid_' + productId + '_' + this.AppCMS.getApiUrl()
      let cachedProductVariations: any = await this.cache.get(key);

      if (cachedProductVariations && cachedProductVariations.data) {
        resolve(cachedProductVariations.data)
      } else {
        setTimeout(() => {
          this.AppCMS.loadPluginData('woocommerceextend', {
            limit: 100,
            per_page: 100,
          }, ['products',  productId, 'variations'])
            .then((response) => {
              this.cache.set(key, response);
              resolve(response)
            })
            .catch((error) => {
              console.warn('error', error)
              reject('Produkt-Variationen nicht gefunden')
            })
        }, iDelay)
      }
    })
  }

  getOrderByUid(orderId: number) {
    return this.AppCMS.loadPluginData('woocommerceextend', {}, [
      'order',
      orderId,
    ])
  }

  getShippingZones() {
    return this.AppCMS.loadPluginData('woocommerceextend', {}, [
      'shippingZones',
    ])
  }

  getShippingZoneLocations(zoneId: number) {
    return new Promise(async (resolve, reject) => {
      let cacheKey =
        this.AppCMS.getApiUrl() +
        '/woocommerceextend/shippingZones/' +
        zoneId +
        '/locations'
      let cachedLocations: any = await this.cache.get(cacheKey);

      if (cachedLocations && cachedLocations.data) {
        resolve(JSON.parse(cachedLocations.data))
      } else {
        this.AppCMS.loadPluginData('woocommerceextend', {}, [
          'shippingZones',
          zoneId,
          'locations',
        ])
          .then((locations) => {
            this.cache.set(cacheKey, locations)
            resolve(locations)
          })
          .catch(reject)
      }
    })
  }

  getShippingZoneMethods(zoneId: number, apiUrl: string|null = null) {
    return new Promise(async (resolve, reject) => {
      let cacheKey =
        (apiUrl || this.AppCMS.getApiUrl()) +
        '/woocommerceextend/shippingZones/' +
        zoneId +
        '/methods'
      let cachedMethods: any = await this.cache.get(cacheKey)
      let _apiUrl = this.AppCMS.getApiUrl()

      if (apiUrl) {
        this.AppCMS.setApiUrl(apiUrl)
      }

      if (cachedMethods && cachedMethods.data) {
        resolve(JSON.parse(cachedMethods.data))
      } else {
        this.AppCMS.loadPluginData('woocommerceextend', {}, [
          'shippingZones',
          zoneId,
          'methods',
        ])
          .then((methods) => {
            this.cache.set(cacheKey, methods)

            if (_apiUrl) {
              this.AppCMS.setApiUrl(_apiUrl)
            }

            resolve(methods)
          })
          .catch(reject)
      }
    })
  }

  getVariationByProduct(product: product) {
    return new Promise((resolve, reject) => {
      new Promise((resolve, reject) => {
        if (
          product.variations &&
          product.variations[0] &&
          product.variations[0] == parseInt(product.variations[0])
        ) {
          this.getProductVariations(product.id)
          .then((variations: product[]) => {
           product.variations = variations;
           resolve(product);
          })
          .catch(reject);
        } else {
          resolve(product)
        }
      })
        .then((product: product) => {
          let attributesFilter = {}

          if (product.metaData) {
            Object.keys(product.metaData).forEach((metaDataKey: string) => {
              let metaDataValue = product.metaData[metaDataKey]
              if (metaDataKey[0] !== '_') {
                attributesFilter[metaDataKey] = metaDataValue
              }
            })
          }

          let filteredVariationFilter = product.variations.filter(
            (_variation: any) => {
              let blMatch = true;
              if (_variation.attributes && _variation.attributes.length) {
                _variation.attributes.forEach((_attribute: any) => {
                  if (attributesFilter.hasOwnProperty(_attribute.name)) {
                    blMatch = blMatch
                      ? attributesFilter[_attribute.name].indexOf(
                          _attribute.option,
                        ) !== -1
                      : false
                  }
                })
              }
              return blMatch;
            },
          )

          let filteredVariation = filteredVariationFilter[0] || null
          //console.log('filteredVariation', filteredVariation)

          // add missing data from parent to variant
          if (filteredVariation) {
            filteredVariation.categories =
              filteredVariation.categories || product.categories
            filteredVariation.images =
              filteredVariation.images || product.images
            filteredVariation.metaData =
              filteredVariation.metaData || product.metaData
            filteredVariation.name = filteredVariation.name || product.name
          }

          if (product.amount) {
            filteredVariation.amount = product.amount
          }

          resolve(filteredVariation)
        })
        .catch(reject)
    })
  }

  getWorkload(blForceReload = false) {
    return new Promise((resolve, reject) => {
      if (this.workload && !blForceReload) {
        resolve(this.workload)
      } else {
        this.AppCMS.loadPluginData('woocommerceextend', {}, ['workload'])
          .then((workload) => {
            this.workload = workload
            resolve(workload)
          })
          .catch(reject)
      }
    })
  }

  parseDescriptionToProductItems(product: any) {
    let items = []
    let p = $(
      '<div>' + product.description.replace(/(?:\r\n|\r|\n)/g, '') + '</div>',
    )
      .find('p')
      .eq(1)
    let html = p && p.length ? p.html() : null
    let descriptionExplode = html ? html.split('<br>') : null
    if (descriptionExplode) {
      descriptionExplode.forEach((string: string, index: number) => {
        let stringExplode = string.split('x ')
        if (stringExplode.hasOwnProperty(1)) {
          items.push({
            id: index,
            amount: parseInt(stringExplode[0]),
            name: stringExplode[1],
          })
        }
      })
    }
    return items
  }

  parseCategories(categories: productCategory[]) {
    return categories;
  }

  parseProducts(products: any, options: any = {}) {
    return new Promise((resolve, reject) => {
      products = JSON.parse(JSON.stringify(products))

      if (products && products.length) {
        let iWithVariations = 0
        products.forEach((product: any, index: any) => {
          if (products[index]) {
            products[index].uid = products[index].uid || products[index].id
            products[index].metaData = products[index].metaData || {}

            if(products[index].attributes && products[index].attributes.length) {
              products[index].attributes.forEach((productAttribute: productAttribute, attributeIndex: number) => {
                products[index].attributes[attributeIndex].label = this.tools.titleCase(productAttribute.name.replace(/-/g, ' '));
              });
            }

            new Promise((resolve, reject) => {
              if (
                product.variations &&
                product.variations.length &&
                !options.withoutDeepParsing
              ) {
                iWithVariations++
                this.getProductVariations(product.id, iWithVariations * 750)
                  .then((variations: any) => {
                    let minPrice: number = null,
                      maxPrice: number = null,
                      price: number = null
                    variations.forEach((variation: any) => {
                      price = parseFloat(variation.price)
                      maxPrice =
                        maxPrice <= price ? price : maxPrice ? maxPrice : price
                      minPrice =
                        minPrice >= price ? price : minPrice ? minPrice : price
                    })
                    product.variations = variations
                    product.price =
                      minPrice !== maxPrice
                        ? minPrice + ' - ' + maxPrice
                        : minPrice
                    resolve(product)
                  })
                  .catch((error) => {
                    console.warn('variations error:', error)
                    resolve(product)
                  })
              } else {
                resolve(product)
              }
            })
              .then((product: any) => {
                products[index] = product

                if (!products.hasOwnProperty(index + 1)) {
                  resolve(products)
                }
              })
              .catch((error) => {
                console.warn('error with product', product, error)
                if (!products.hasOwnProperty(index + 1)) {
                  resolve(products)
                }
              })
          }
        })
      }
    })
  }

  async schedulePlannedOrder(basketStorage: any) {
    let Articles = []
    basketStorage.forEach((Article: any) => {
      Articles.push(this.basket.toBasketItem(Article))
    })

    return this.AppCMS.loadPluginData(
      'woocommerceextend',
      {
        basket: Articles,
        basketInfo: await this.basket.calculateBasketInfo(basketStorage),
        tmpOrder: this.ordersService.getTmpOrder(),
        user: this.userService.getUser(),
      },
      ['schedulePlannedOrder'],
    )
  }

  useCoupon(coupon: any) {
    //amount
    //date_expires
    //description
    //excluded_product_categories
    //excluded_product_ids
    //exclude_sale_items
    //product_ids
    //usage_limit
    //usage_limit_per_user

    switch (coupon.discount_type) {
      case 'percent':
        return this.useCouponWithDiscountTypePercentage(coupon)
      default:
        console.warn('not supported discount type:', coupon.discount_type)
        break
    }
  }

  useCouponWithDiscountTypePercentage(coupon: any) {
    return new Promise((resolve, reject) => {
      if (coupon.product_ids && coupon.product_ids.length) {
        this.useCouponWithDiscountTypePercentageWithProductIds(coupon)
          .then(resolve)
          .catch(reject)
      } else if (
        coupon.product_categories &&
        coupon.product_categories.length
      ) {
        this.useCouponWithDiscountTypePercentageWithProductCategories(coupon)
          .then(resolve)
          .catch(reject)
      } else {
        this.useCouponWithDiscountTypePercentageOnAll(coupon)
          .then(resolve)
          .catch(reject)
      }
    })
  }

  useCouponWithDiscountTypePercentageOnAll(coupon: any) {
    return new Promise((resolve, reject) => {
      this.basket.getBasket()
      .then((basketStorage: basketItem[]) => {
        if (basketStorage && basketStorage.length) {
          basketStorage.forEach((basketItem: any) => {
            basketItem.price = 0
          })
          this.basket.setBasket(basketStorage).then(resolve).catch(reject)
        }
      })
      .catch(reject);
    })
  }

  useCouponWithDiscountTypePercentageWithProductCategories(coupon: any) {
    return new Promise(async (resolve, reject) => {
      this.basket.getBasket()
      .then((basketStorage: basketItem[]) => {
        if (basketStorage && basketStorage.length) {
          basketStorage.forEach((basketItem: any) => {
            if (basketItem.categories && basketItem.categories.length) {
              let productCategoryIds = []
              basketItem.categories.forEach((category: any) => {
                productCategoryIds.push(category.id)
              })
              let blMatch = false
              coupon.product_categories.forEach((couponCategory: number) => {
                blMatch = blMatch || productCategoryIds.includes(couponCategory)
              })
              if (blMatch) {
                basketItem.price = '0.00'
              }
            }
          })
          this.basket.setBasket(basketStorage).then(resolve).catch(reject)
        }
      })
      .catch(reject);
    })
  }

  useCouponWithDiscountTypePercentageWithProductIds(coupon: any) {
    return new Promise((resolve, reject) => {
      let products = []
      coupon.product_ids.forEach((productId: any, index: number) => {
        this.getProductByUid(productId)
          .then((product: any) => {
            product.price =
              (parseFloat(product.price) / 100) * (100 - coupon.amount)
            products.push(product)

            this.basket
              .addCouponLine(coupon)
              .then(() => {
                this.basket
                  .add(product)
                  .then(() => {
                    if (products.length === coupon.product_ids.length) {
                      resolve({
                        success: true,
                        products: products,
                      })
                    }
                  })
                  .catch(reject)
              })
              .catch(reject)
          })
          .catch(reject)
      })
    })
  }
}
