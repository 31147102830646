import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { BionicReadingService } from '../../../services/bionic-reading.service';
import { EventsService } from '../../../services/events.service';
import { ThemesService } from '../../../services/themes.service';
import { ConfigService } from '../../../services/config.service';

import { ScreenBrightness } from '@capacitor-community/screen-brightness';

@Component({
  selector: 'app-post-reader-appearance-popup',
  templateUrl: './post-reader-appearance-popup.page.html',
  styleUrls: ['./post-reader-appearance-popup.page.scss'],
})
export class PostReaderAppearancePopupPage implements OnInit {

  appConfig: pipelineAppConfig;

  readerPage: any;

  view: any = {
    brightness: 50,
    size: 2,
  };

  constructor(
    private bionicReading: BionicReadingService,
    private config: ConfigService,
    private events: EventsService,
    private navParams: NavParams,
    public themes: ThemesService,
  ) {
    this.appConfig = this.config.getConfig();
    this.view.size = parseInt(this.navParams.get('size') || this.view.size);
  }

  ionViewWillEnter() {
    this.loadBrightness();
  }

  async loadBionicReadingState() {
    this.view.enableBionicReading = await this.bionicReading.isEnabled();
  }

  async loadBrightness() {
    try {
      const {brightness: currentBrightness} = await ScreenBrightness.getBrightness();
      console.log('> currentBrightness', currentBrightness);

      this.view.brightness = parseInt((!!currentBrightness ? (currentBrightness * 100) : this.view.brightness) as string);
    } catch(e) {
      console.warn('> brightness error', e);
    }
  }

  ngOnInit() {
    this.loadBionicReadingState();
  }

  onBrightnessChanged() {
    try {
      ScreenBrightness.setBrightness({
        brightness: (this.view.brightness / 100),
      });
    } catch(e) {
      console.warn('set brightness error', e);
    }
  }

  toggleBionicReading(bl: boolean = false, blUpdate: boolean = true) {
    this.view.enableBionicReading = bl;
    this.bionicReading.toggle(bl);
  }

  toggleDarkMode(bl: boolean = false, blUpdate: boolean = true) {
    this.view.darkMode = bl;
    if(blUpdate) {
      this.themes.toggleDarkTheme(!!this.view.darkMode);
    }
  }

  toggledDarkMode(bl: boolean|null = null) {
    bl = (bl !== null ? bl : this.view.darkMode);
    this.toggleDarkMode(bl);
  }

  toggleSize(direction: boolean = false) {
    if(direction) {
      this.view.size = this.view.size === 3 ? 1 : this.view.size ? this.view.size+1 : 1;
    } else {
      this.view.size = this.view.size === 1 ? 3 : this.view.size ? this.view.size-1 : 3;
    }
    this.events.publish('post:reader:font:size:updated', this.view.size);
  }

}
