import { Injectable } from '@angular/core';

//import { ApplePay, IOrder, IOrderItem } from '@ionic-native/apple-pay/ngx';
import { BasketService } from './basket.service';

@Injectable({
  providedIn: 'root'
})
export class ApplePayService {

  constructor(
    //private applePay: ApplePay,
    private basket: BasketService,
  ) {

  }

  async canMakePayments() {
    return false;
    //return this.applePay.canMakePayments();
  }

  pay() {
    return new Promise(async (resolve, reject) => {
      let basketStorage: any = await this.basket.getBasket();

      /*
      this.applePay.canMakePayments()
        .then(blCanMakePayments => {
          if (blCanMakePayments) {

            try {

              let Articles: IOrderItem[] = [];

              if (basketStorage && basketStorage.length) {
                basketStorage.forEach((Article: any) => {
                  let item: IOrderItem = {
                    label: Article.name,
                    amount: parseFloat(Article.price),
                  };
                  Articles.push(item);
                });
              }

              let order: IOrder = {
                items: Articles,
                shippingMethods: [
                  {
                    identifier: 'Lieferung',
                    label: 'Lieferung',
                    detail: 'Wir liefern Dir das Essen an die Tür',
                    amount: 0,
                  }
                ],
                //paymentSummaryItems: Articles,
                merchantIdentifier: 'merchant.de.tie',
                currencyCode: 'EUR',
                countryCode: 'DE',
                billingAddressRequirement: ['name', 'email', 'phone'],
                shippingAddressRequirement: 'none',
                //shippingType: ,
                supportedNetworks: ['visa', 'amex', 'masterCard', 'discover'],
                merchantCapabilities: ['3ds', 'debit', 'credit'],
              };

              this.applePay.makePaymentRequest(order)
                .then((response: any) => {
                  this.applePay.completeLastTransaction('success');
                  resolve(response);
                })
                .catch((error: any) => {
                  this.applePay.completeLastTransaction('failure');
                  reject(error);
                });

            } catch (error: any) {
              this.applePay.completeLastTransaction('failure');
              reject(error);
            }

          } else {
            reject('Apple Pay ist nicht verfügbar');
          }
        })
        .catch(error => {
          reject(error || 'Apple Pay ist nicht verfügbar');
        });
      */
     
    });
  }

}