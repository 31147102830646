import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { pipelineAppConfig } from '../config/variables';

const routes: Routes = [
  {
    path: '',
    redirectTo: (pipelineAppConfig.routes && pipelineAppConfig.routes.home ? pipelineAppConfig.routes.home : 'home'),
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/account/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'interests',
    loadChildren: () => import('./pages/interests/interests/interests.module').then( m => m.InterestsPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/account/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/account/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/account/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'report-content',
    loadChildren: () => import('./pages/security/report-content/report-content.module').then( m => m.ReportContentPageModule)
  },
  {
    path: 'post',
    loadChildren: () => import('./pages/post/post/post.module').then( m => m.PostPageModule)
  },
  {
    path: 'post/:postId/comments',
    loadChildren: () => import('./pages/post/post-comments/post-comments.module').then( m => m.PostCommentsPageModule)
  },
  {
    path: 'edit-post',
    loadChildren: () => import('./pages/post/edit-post/edit-post.module').then( m => m.EditPostPageModule)
  },
  {
    path: 'edit-post-settings',
    loadChildren: () => import('./pages/post/edit-post-settings/edit-post-settings.module').then( m => m.EditPostSettingsPageModule)
  },
  {
    path: 'edit-post-translations',
    loadChildren: () => import('./pages/post/edit-post-translations-modal/edit-post-translations-modal.module').then( m => m.EditPostTranslationsModalPageModule)
  },
  {
    path: 'detail',
    loadChildren: () => import('./pages/shop/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'dynamic/:pageId',
    loadChildren: () => import('./pages/dynamic/dynamic.module').then( m => m.DynamicPageModule)
  },
  {
    path: 'basket',
    loadChildren: () => import('./pages/shop/basket/basket.module').then( m => m.BasketPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/shop/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'configure-product',
    loadChildren: () => import('./pages/shop/configure-product/configure-product.module').then( m => m.ConfigureProductPageModule)
  },
  {
    path: 'coupon',
    loadChildren: () => import('./pages/coupons/coupon/coupon.module').then( m => m.CouponPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/shop/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then( m => m.ContactsPageModule)
  },
  {
    path: 'share-internal',
    loadChildren: () => import('./pages/share-internal/share-internal.module').then( m => m.ShareInternalPageModule)
  },
  {
    path: 'request-invitation',
    loadChildren: () => import('./pages/invitations/request-invitation/request-invitation.module').then( m => m.RequestInvitationPageModule)
  },
  {
    path: 'redeem-invitation',
    loadChildren: () => import('./pages/invitations/redeem-invitation/redeem-invitation.module').then( m => m.RedeemInvitationPageModule)
  },
  {
    path: 'abonnement',
    loadChildren: () => import('./pages/account/abonnement/abonnement.module').then( m => m.AbonnementPageModule)
  },
  {
    path: 'regional-create-source',
    loadChildren: () => import('./pages/regions/regional-create-source/regional-create-source.module').then( m => m.RegionalCreateSourcePageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/account/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'rate-post',
    loadChildren: () => import('./pages/post/rate-post/rate-post.module').then( m => m.RatePostPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./pages/account/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },
  {
    path: 'privacy-intro',
    loadChildren: () => import('./pages/intro/privacy-intro/privacy-intro.module').then( m => m.PrivacyIntroPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'experiments',
    loadChildren: () => import('./pages/experiments/experiments.module').then( m => m.ExperimentsPageModule)
  },
  {
    path: 'appearance',
    loadChildren: () => import('./pages/appearance/appearance.module').then( m => m.AppearancePageModule)
  },
  {
    path: 'verified-info',
    loadChildren: () => import('./pages/account/verified-info/verified-info.module').then( m => m.VerifiedInfoPageModule)
  },
  {
    path: 'telemetry',
    loadChildren: () => import('./pages/account/telemetry/telemetry.module').then( m => m.TelemetryPageModule)
  },
  {
    path: 'view/:slug',
    loadChildren: () => import('./pages/view/view.module').then( m => m.ViewPageModule)
  },
  {
    path: 'card-options',
    loadChildren: () => import('./pages/legacy/card-options/card-options.module').then( m => m.CardOptionsPageModule)
  },
  {
    path: 'invisible-screen',
    loadChildren: () => import('./pages/account/invisible-screen/invisible-screen.module').then( m => m.InvisibleScreenPageModule)
  },
  {
    path: 'code/verify/:codeId',
    loadChildren: () => import('./pages/invitations/verify-code/verify-code.module').then( m => m.VerifyCodePageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'dating',
    loadChildren: () => import('./pages/dating/dating-dashboard/dating-dashboard.module').then(m => m.DatingDashboardPageModule)
  },
  {
    path: 'dating/explore',
    loadChildren: () => import('./pages/dating/dating-finder/dating-finder.module').then(m => m.DatingFinderPageModule)
  },
  {
    path: 'dating/intro',
    loadChildren: () => import('./pages/dating/dating-intro/dating-intro.module').then(m => m.DatingIntroPageModule)
  },
  {
    path: 'dating/profile/:profileId',
    loadChildren: () => import('./pages/dating/dating-profile/dating-profile.module').then(m => m.DatingProfilePageModule)
  },
  {
    path: 'story',
    loadChildren: () => import('./pages/stories/story/story.module').then( m => m.StoryPageModule)
  },
  {
    path: 'post-information',
    loadChildren: () => import('./pages/post/post-information/post-information.module').then( m => m.PostInformationPageModule)
  },
  {
    path: 'messenger',
    loadChildren: () => import('./pages/chat/messenger/messenger.module').then( m => m.MessengerPageModule)
  },
  {
    path: 'connect-account-with-siwa',
    loadChildren: () => import('./pages/account/connect-account-with-siwa/connect-account-with-siwa.module').then( m => m.ConnectAccountWithSiwaPageModule)
  },
  {
    path: 'add-region',
    loadChildren: () => import('./pages/regions/add-region/add-region.module').then( m => m.AddRegionPageModule)
  },
  {
    path: 'cookies',
    loadChildren: () => import('./pages/cookies/cookies.module').then( m => m.CookiesPageModule)
  },
  {
    path: 'word-list',
    loadChildren: () => import('./pages/word-list/word-list/word-list.module').then( m => m.WordListPageModule)
  },
  {
    path: 'edit-word-list-item',
    loadChildren: () => import('./pages/word-list/edit-word-list-item/edit-word-list-item.module').then( m => m.EditWordListItemPageModule)
  },
  {
    path: 'region-setup',
    loadChildren: () => import('./pages/regions/region-setup/region-setup.module').then( m => m.RegionSetupPageModule)
  },
  {
    path: 'create-chat-modal',
    loadChildren: () => import('./pages/modals/create-chat-modal/create-chat-modal.module').then( m => m.CreateChatModalPageModule)
  },
  {
    path: 'create-word-list-entry',
    loadChildren: () => import('./pages/word-list/create-word-list-entry/create-word-list-entry.module').then( m => m.CreateWordListEntryPageModule)
  },
  {
    path: 'choose-avatar',
    loadChildren: () => import('./pages/chooser/choose-avatar/choose-avatar.module').then( m => m.ChooseAvatarPageModule)
  },
  {
    path: 'transparency-settings',
    loadChildren: () => import('./pages/account/transparency-settings/transparency-settings.module').then( m => m.TransparencySettingsPageModule)
  },
  {
    path: 'create-contents-banner',
    loadChildren: () => import('./pages/account/create-contents-banner/create-contents-banner.module').then( m => m.CreateContentsBannerPageModule)
  },
  {
    path: 'choose-cover',
    loadChildren: () => import('./pages/chooser/choose-cover/choose-cover.module').then( m => m.ChooseCoverPageModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./pages/modals/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'pick-story',
    loadChildren: () => import('./pages/stories/pick-story/pick-story.module').then( m => m.PickStoryPageModule)
  },
  {
    path: 'sharing-modal',
    loadChildren: () => import('./pages/modals/sharing-modal/sharing-modal.module').then( m => m.SharingModalPageModule)
  },
  {
    path: 'web-uploader',
    loadChildren: () => import('./pages/web-uploader/web-uploader.module').then( m => m.WebUploaderPageModule)
  },
  {
    path: 'choose-action-sheet',
    loadChildren: () => import('./pages/chooser/choose-action-sheet/choose-action-sheet.module').then( m => m.ChooseActionSheetPageModule)
  },
  {
    path: 'collection-picker',
    loadChildren: () => import('./pages/collections/collection-picker/collection-picker.module').then( m => m.CollectionPickerPageModule)
  },
  {
    path: 'custom-collection-viewer',
    loadChildren: () => import('./pages/collections/custom-collection-viewer/custom-collection-viewer.module').then( m => m.CustomCollectionViewerPageModule)
  },
  {
    path: 'configure-collection',
    loadChildren: () => import('./pages/collections/configure-collection/configure-collection.module').then( m => m.ConfigureCollectionPageModule)
  },
  {
    path: 'icon-picker',
    loadChildren: () => import('./pages/legacy/icon-picker/icon-picker.module').then( m => m.IconPickerPageModule)
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/security/security/security.module').then( m => m.SecurityPageModule)
  },
  {
    path: 'change-email',
    loadChildren: () => import('./pages/account/change-email/change-email.module').then( m => m.ChangeEmailPageModule)
  },
  {
    path: 'swipe-interests',
    loadChildren: () => import('./pages/interests/swipe-interests/swipe-interests.module').then( m => m.SwipeInterestsPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'connect',
    loadChildren: () => import('./pages/account/connect/connect.module').then( m => m.ConnectPageModule)
  },
  {
    path: 'delivery',
    loadChildren: () => import('./pages/shop/delivery/delivery.module').then( m => m.DeliveryPageModule)
  },
  {
    path: 'intro-people',
    loadChildren: () => import('./pages/intro/intro-people/intro-people.module').then( m => m.IntroPeoplePageModule)
  },
  {
    path: 'intro-reading-settings',
    loadChildren: () => import('./pages/intro/intro-reading-settings/intro-reading-settings.module').then( m => m.IntroReadingSettingsPageModule)
  },
  {
    path: 'intro-slider',
    loadChildren: () => import('./pages/intro/intro-slider/intro-slider.module').then( m => m.IntroSliderPageModule)
  },
  {
    path: 'social-media-images-preview',
    loadChildren: () => import('./pages/preview/social-media-images-preview/social-media-images-preview.module').then( m => m.SocialMediaImagesPreviewPageModule)
  },
  {
    path: 'media-admin',
    loadChildren: () => import('./pages/media/media-admin/media-admin.module').then( m => m.MediaAdminPageModule)
  },
  {
    path: 'media/folder/:folderId/settings',
    loadChildren: () => import('./pages/media/media-folder-settings/media-folder-settings.module').then( m => m.MediaFolderSettingsPageModule)
  },
  {
    path: 'media/:itemId',
    loadChildren: () => import('./pages/media/view-media/view-media.module').then( m => m.ViewMediaPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/shop/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'posts-admin',
    loadChildren: () => import('./pages/post/posts-admin/posts-admin.module').then( m => m.PostsAdminPageModule)
  },
  {
    path: 'posts-admin-settings',
    loadChildren: () => import('./pages/post/posts-admin-settings/posts-admin-settings.module').then( m => m.PostsAdminSettingsPageModule)
  },
  {
    path: 'post-reader-appearance-popup',
    loadChildren: () => import('./pages/post/post-reader-appearance-popup/post-reader-appearance-popup.module').then( m => m.PostReaderAppearancePopupPageModule)
  },
  {
    path: 'preview-social-sharing-image',
    loadChildren: () => import('./pages/preview/preview-social-sharing-image/preview-social-sharing-image.module').then( m => m.PreviewSocialSharingImagePageModule)
  },
  {
    path: 'preview-social-sharing-image-filters',
    loadChildren: () => import('./pages/preview/preview-social-sharing-image-filters/preview-social-sharing-image-filters.module').then( m => m.PreviewSocialSharingImageFiltersPageModule)
  },
  {
    path: 'change-account',
    loadChildren: () => import('./pages/account/change-account/change-account.module').then( m => m.ChangeAccountPageModule)
  },
  {
    path: 'order-by-modal',
    loadChildren: () => import('./pages/modals/order-by-modal/order-by-modal.module').then( m => m.OrderByModalPageModule)
  },
  {
    path: 'confirm-modal',
    loadChildren: () => import('./pages/modals/confirm-modal/confirm-modal.module').then( m => m.ConfirmModalPageModule)
  },
  {
    path: 'notifications-settings',
    loadChildren: () => import('./pages/account/notifications-settings/notifications-settings.module').then( m => m.NotificationsSettingsPageModule)
  },
  {
    path: 'apple-watch-settings',
    loadChildren: () => import('./pages/apple-watch-settings/apple-watch-settings.module').then( m => m.AppleWatchSettingsPageModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/shop/shop/shop.module').then( m => m.ShopPageModule)
  },
  {
    path: 'job-posts',
    loadChildren: () => import('./pages/jobs/job-posts/job-posts.module').then(m => m.JobPostsPageModule)
  },
  {
    path: 'job-post/:jobPostId',
    loadChildren: () => import('./pages/jobs/job-post/job-post.module').then(m => m.JobPostPageModule)
  },
  {
    path: 'job-titles',
    loadChildren: () => import('./pages/jobs/job-titles/job-titles.module').then(m => m.JobTitlesPageModule)
  },
  {
    path: 'weclapp-connect',
    loadChildren: () => import('./pages/weclapp/weclapp-connect/weclapp-connect.module').then( m => m.WeclappConnectPageModule)
  },
  {
    path: 'weclapp-connections',
    loadChildren: () => import('./pages/weclapp/weclapp-connections/weclapp-connections.module').then( m => m.WeclappConnectionsPageModule)
  },
  {
    path: 'welove/wallet/options',
    loadChildren: () => import('./pages/welove/welove-wallet-options/welove-wallet-options.module').then( m => m.WeloveWalletOptionsPageModule)
  },
  {
    path: 'looma-history',
    loadChildren: () => import('./pages/looma/looma-history/looma-history.module').then( m => m.LoomaHistoryPageModule)
  },
  {
    path: 'looma-article',
    loadChildren: () => import('./pages/looma/looma-article/looma-article.module').then( m => m.LoomaArticlePageModule)
  },
  {
    path: 'looma-articles',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-article-category',
    loadChildren: () => import('./pages/looma/looma-article-category/looma-article-category.module').then( m => m.LoomaArticleCategoryPageModule)
  },
  {
    path: 'looma-article-categories',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-customer',
    loadChildren: () => import('./pages/looma/looma-customer/looma-customer.module').then( m => m.LoomaCustomerPageModule)
  },
  {
    path: 'looma-customers',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-lead',
    loadChildren: () => import('./pages/looma/looma-lead/looma-lead.module').then( m => m.LoomaLeadPageModule)
  },
  {
    path: 'looma-leads',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-offer',
    loadChildren: () => import('./pages/looma/looma-offer/looma-offer.module').then( m => m.LoomaOfferPageModule)
  },
  {
    path: 'looma-offers',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-order',
    loadChildren: () => import('./pages/looma/looma-order/looma-order.module').then( m => m.LoomaOrderPageModule)
  },
  {
    path: 'looma-orders',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-parties',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-party',
    loadChildren: () => import('./pages/looma/looma-party/looma-party.module').then( m => m.LoomaPartyPageModule)
  },
  {
    path: 'looma-project-order',
    loadChildren: () => import('./pages/looma/looma-project-order/looma-project-order.module').then( m => m.LoomaProjectOrderPageModule)
  },
  {
    path: 'looma-project-orders',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-sales-price',
    loadChildren: () => import('./pages/looma/looma-sales-price/looma-sales-price.module').then( m => m.LoomaSalesPricePageModule)
  },
  {
    path: 'looma-sales-prices',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-search',
    loadChildren: () => import('./pages/looma/looma-search/looma-search.module').then( m => m.LoomaSearchPageModule)
  },
  {
    path: 'looma-supplier',
    loadChildren: () => import('./pages/looma/looma-supplier/looma-supplier.module').then( m => m.LoomaSupplierPageModule)
  },
  {
    path: 'looma-suppliers',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-user',
    loadChildren: () => import('./pages/looma/looma-user/looma-user.module').then( m => m.LoomaUserPageModule)
  },
  {
    path: 'looma-users',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'looma-view',
    loadChildren: () => import('./pages/looma/looma-view/looma-view.module').then( m => m.LoomaViewPageModule)
  },
  {
    path: 'company/:companyId',
    loadChildren: () => import('./pages/companies/company/company.module').then( m => m.CompanyPageModule)
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/companies/companies/companies.module').then( m => m.CompaniesPageModule)
  },
  {
    path: 'group/:groupId',
    loadChildren: () => import('./pages/groups/group/group.module').then( m => m.GroupPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups/groups.module').then( m => m.GroupsPageModule)
  },
  {
    path: 'groups/create',
    loadChildren: () => import('./pages/groups/create-group/create-group.module').then( m => m.CreateGroupPageModule)
  },
  {
    path: 'survey/:groupId',
    loadChildren: () => import('./pages/surveys/survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: 'surveys',
    loadChildren: () => import('./pages/surveys/surveys/surveys.module').then( m => m.SurveysPageModule)
  },
  {
    path: 'tv',
    loadChildren: () => import('./pages/tv/tv.module').then( m => m.TvPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'write-feed-post',
    loadChildren: () => import('./pages/feeds/feed/write-feed-post/write-feed-post.module').then( m => m.WriteFeedPostPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
