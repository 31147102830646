import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../../services/modal.service';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-media-folder-settings',
  templateUrl: './media-folder-settings.page.html',
  styleUrls: ['./media-folder-settings.page.scss'],
})
export class MediaFolderSettingsPage implements OnInit {

  folder: mediaFolder;
  
  view: any = {
    segment: 'general',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
  ) {

  }

  addProject() {
    /*
    this.projectsService.pick({
      multiple: true,
    })
    .then((projects: project[]) => {
      console.log('> projects', projects);
      this.doRefresh();
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
    */
  }

  createProject() {
    /*
    this.projectsService.create()
    .then(() => {
      this.doRefresh();
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
    */
  }

  dismiss(data: any = null) {
    this.modalService.dismiss(data);
  }

  doRefresh() {
    this.loadAccess(true);
  }

  loadAccess(blForceRefresh: boolean = false) {
    console.log('> load access', blForceRefresh);
  }

  ngOnInit() {

  }

  update() {
    
  }

}
