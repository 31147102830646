import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormsService } from '../../services/forms.service';
import { NavController } from '@ionic/angular';
import { UserService } from '../../services/user.service';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackPage implements OnInit {

  form: form;

  formConfig: any = {
    validated: false,
  };

  formId: number = 2414;

  user: user;

  view: any = {
    stars: ['star-outline', 'star-outline', 'star-outline', 'star-outline', 'star-outline'],
    step: 0,
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    private events: EventsService,
    private formsService: FormsService,
    public userService: UserService,
    private navCtrl: NavController,
    private zone: NgZone,
  ) {
    this.user = this.userService.getUser() || {};
  }

  detectChanges() {
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
  }

  dismiss() {
    this.navCtrl.pop();
  }

  doRefresh(event: any) {
    this.loadForm(true)
    .then(() => {
      event.target.complete();
    })
    .catch((error: any) => {
      event.target.complete();
      this.events.publish('error', error);
    });
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
    this.detectChanges();

    if(!this.form) {
      this.loadForm()
      .catch(error => {
        this.events.publish('error', error);
      });
    } else
    if(this.view.formBackup) {
      this.form = this.view.formBackup;
      this.detectChanges();
    }
  }
  
  loadForm(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.formsService.getByUid(this.formId, blForceRefresh)
      .then((form: any) => {

        if(!!form && !!form.fields) {
          form.fields = form.fields.map((field: any) => {

            if(field.type === 'stars') {
              field.rating = field.rating || { stars: this.view.stars };
            }

            return field;
          });
        }

        this.form = form;
        this.view.formBackup = form;

        if(form && form.fields && form.fields.length) {
          form.fields.forEach((field: any) => {
            this.view.steps = (this.view.steps > field.group ? this.view.steps : field.group);
          });
        }
  
        this.detectChanges();
        resolve(form);
      })
      .catch(reject);
    });
  }

  next() {
    this.view.step++;
  }

  ngOnInit() {
  }

  onChange(rating: number, iField: number) {
    this.form.fields[iField].answer = rating;

    // @todo validate "required" key of each field
    this.formConfig.validated = true;

    this.detectChanges();
  }

  setRating(field: any, iField: number, iStar: number) {
    console.log('set rating', field, iField, iStar);

    if(!this.form.fields[iField]) {
      return false;
    }

    this.form.fields[iField].answer = iStar;
    
    if(!!this.form.fields[iField].rating && !!this.form.fields[iField].rating.stars) {
      this.form.fields[iField].rating.stars = this.form.fields[iField].rating.stars.map((_star: string, _index: number) => {
        return (iStar >= _index ? 'star' : 'star-outline');
      });
    }

    // @todo validate "required" key of each field
    this.formConfig.validated = true;
    this.detectChanges();
  }

  submit() {
    let form: any = JSON.parse(JSON.stringify(this.form));

    if(!!form && !!form.fields) {
      form.fields = form.fields.map((_field: formField) => {
        delete _field.rating;

        return _field;
      });
    }

    this.formsService.submit(form)
    .then((response: any) => {
      this.events.publish('alert', {
        header: 'Dankeschön!',
        message: 'Vielen Dank für die Rückmeldung! Wir werden es nutzen, um uns weiter zu verbessern.',
      });
      this.dismiss();
    })
    .catch(error => {
      this.events.publish('error', error);
    });
  }

}
