import { Injectable } from "@angular/core";
import { ActionSheetController, LoadingController } from "@ionic/angular";

import { Camera } from "@ionic-native/camera/ngx";
import { Crop } from "@ionic-native/crop/ngx";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";

import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from "@ionic-native/file-transfer/ngx";

import { hostUrl } from "src/config/variables";

import { AppcmsService } from "./appcms.service";
import { ChooserService } from "./chooser.service";
import { EventsService } from "./events.service";
import { ModalService } from "./modal.service";
import { TranslationService } from "./translation.service";
import { UserService } from "./user.service";

import { WebUploaderPage } from "../pages/web-uploader/web-uploader.page";

import * as $ from "jquery";
import { MediaFolderSettingsPage } from "../pages/media/media-folder-settings/media-folder-settings.page";

@Injectable({
  providedIn: "root",
})
export class MediaextendService {

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private AppCMS: AppcmsService,
    public camera: Camera,
    private chooser: ChooserService,
    private crop: Crop,
    private events: EventsService,
    private fileTransfer: FileTransfer,
    private loadingCtrl: LoadingController,
    private modalService: ModalService,
    private photoViewer: PhotoViewer,
    private translations: TranslationService,
    private UserService: UserService
  ) {}

  apply(sourceType: any, options: any = {}) {
    return new Promise((resolve, reject) => {
      var options: any = {
        quality: 75,
        destinationType: this.camera.DestinationType.FILE_URI,
        sourceType: sourceType, // 0:Photo Library, 1=Camera, 2=Saved Photo Album
        encodingType: 0, // 0=JPG 1=PNG
      };

      this.camera
        .getPicture(options)
        .then((fileUrl) => {
          this.crop
            .crop(fileUrl, {
              quality: 80,
              targetHeight: 1080,
              targetWidth: 1920,
            })
            .then((newPath) => {
              this.onCapturePhoto(newPath.split("?")[0])
                .then(resolve)
                .catch((error: any) => {
                  console.warn("error 3", error);
                  reject(error);
                });
            })
            .catch((error: any) => {
              console.warn("error 2", error);
              reject(error);
            });
        })
        .catch((error: any) => {
          console.warn("error 1", error);
          if (error === "cordova_not_available") {
            this.applyFromWeb()
              .then(resolve)
              .catch((error: any) => {
                console.warn("error 4", error);
                reject(error);
              });
          } else {
            reject(error);
          }
        });
    });
  }

  applyFromWeb(fileURI: string | null = null, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      
      let params: any = Object.assign(options, {
        fileURI: fileURI,
        mediaService: this,
        modalCtrl: this.modalService.getController(),
        multiple: !!options.multiple,
        services: options.services || ["database", "upload", "media_library"],
      });

      let webUploader = await this.modalService.create({
        component: WebUploaderPage,
        componentProps: params,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });

      webUploader.onDidDismiss().then((response: any) => {
        if (response.data && !!response.data.item) {
          resolve({
            items: [response.data.item],
          });
        } else if (
          response.data &&
          response.data.items &&
          response.data.items.length
        ) {
          resolve({
            items: response.data.items,
          });
        } else if (response.data && (response.data.code || response.data.url)) {
          resolve(response.data.code || response.data.url);
        } else {
          reject(response.data);
        }
      });

      await webUploader.present();
    });
  }

  applyYouTubeVideo(options: any = {}) {
    return this.applyFromWeb(
      null,
      Object.assign(options, {
        source: "youtube",
      })
    );
  }

  async choose(options: any = {}) {
    let chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getMediaList(options),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  async chooseEffect(options: any = {}) {
    let chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getEffects(),
      labelKey: "name",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  async chooseFolder(options: any = {}) {
    let chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getFolders({}, true),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  async chooseImages(options: any = {}) {
    let chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getImages(options),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  async chooseFromMedia(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      let buttons = [],
        chooseKey = "choose_" + options.location;

      this.translations
        .get([
          "add_attachment",
          "cancel",
          chooseKey,
          "delete_photo",
          "take_photo",
          "upload_photo",
          "view_attachment",
          "view_photo",
        ])
        .subscribe(async (response: any) => {
          if (options.admin) {
            if (this.hasOwnProperty(options.location)) {
              buttons.push({
                text: response[chooseKey] || chooseKey,
                handler: () => {
                  let provider = this[options.location];
                  try {
                    provider.select().then(resolve).catch(reject);
                  } catch (e) {
                    reject(e);
                  }
                },
              });
            }

            buttons.push({
              text: response.take_photo || "take_photo",
              handler: () => {
                this.apply(this.camera.PictureSourceType.CAMERA, options)
                  .then(resolve)
                  .catch(reject);
              },
            });

            buttons.push({
              text: response.upload_photo || "upload_photo",
              handler: () => {
                this.apply(this.camera.PictureSourceType.PHOTOLIBRARY, options)
                  .then(resolve)
                  .catch(reject);
              },
            });

            if (options.photo) {
              buttons.push({
                text: response.view_photo || "view_photo",
                handler: () => {
                  this.photoViewer.show(options.photo);
                },
              });

              buttons.push({
                text: response.delete_photo || "delete_photo",
                color: "danger",
                handler: () => {
                  this.removeImage(options.photo, options.photoType)
                    .then(resolve)
                    .catch(reject);
                },
              });
            }

            buttons.push({
              text: response.cancel || "cancel",
              role: "cancel",
            });
          } else if (options.photo) {
            buttons.push({
              text: response.view_photo || "view_photo",
              handler: () => {
                this.photoViewer.show(options.photo);
              },
            });
          }

          let actionSheet = await this.actionSheetCtrl.create({
            header: options.admin
              ? response.add_attachment || "add_attachment"
              : response.view_attachment || "view_attachment",
            buttons: buttons,
          });

          await actionSheet.present();
        });
    });
  }

  clearCache() {
    this.camera.cleanup();
  }

  create(media: mediaQueueItem) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        media: media,
      },
      ["media", "create"]
    );
  }

  createFolder(folder: mediaFolder) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        folder: folder,
      },
      ["folders", "create"]
    );
  }

  deleteFolder(folderId: number) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        folder_uid: folderId,
      },
      ["folders", folderId, "delete"]
    );
  }

  deleteMediaItem(mediaId: number) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        uid: mediaId,
      },
      ["delete"]
    );
  }

  deleteQueueItem(itemId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        item: itemId,
      },
      ["media", "queue", "delete"]
    );
  }

  editFolderSettings(folder: mediaFolder) {
    return new Promise(async (resolve, reject) => {

      let modal = await this.modalService.create({
        component: MediaFolderSettingsPage,
        componentProps: {
          folder: folder,
        },
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });

      modal.onDidDismiss().then((response: any) => {
        resolve(response);
      });

      await modal.present();      
    });
  }

  executeMove(mediaId: number, folderId: number) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        source: mediaId,
        target: folderId,
      },
      ["move"]
    );
  }

  fineTuneMediaInput(media: any) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        media: media,
      },
      ["media", "finetune"]
    );
  }

  getAssets(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ['assets'],
      params,
      blForceRefresh
    );
  }

  getByFolder(
    folderId: number,
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {},
      ["folders", folderId],
      params,
      blForceRefresh
    );
  }

  getCreatives(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ['creatives'],
      params,
      blForceRefresh
    );
  }

  getDefaultEffectEditorCSS(layer: any) {
    let selector =
        !!layer && !!layer.settings && !!layer.settings.id
          ? `#${layer.settings.id}`
          : ".animation-wrapper",
      animationName =
        !!layer && !!layer.settings && !!layer.settings.id
          ? `${layer.settings.id}Animation`
          : "customAnimation",
      string: string = `${selector} {
  animation-name: ${animationName};
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

@keyframes ${animationName} {
  0% {
  }
  100% {
  }
}`;

    return string;
  }

  getDefaultEffectEditorHTML(layer: any) {
    let string: string = "";

    return string;
  }

  getDefaultEffectEditorJS(layer: any) {
    let selector =
        !!layer && !!layer.settings && !!layer.settings.id
          ? `#${layer.settings.id}`
          : ".animation-wrapper",
      string: string = `var element = document.querySelector('${selector}');`;

    return string;
  }

  getEffects(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "effects"],
      params,
      blForceRefresh
    );
  }

  getFolders(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    if (!options.hasOwnProperty("include_items")) {
      options.include_items = false;
    }

    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ["folders"],
      params,
      blForceRefresh
    );
  }

  getFolderByUid(
    folderId: number,
    blForceRefresh: boolean = false,
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {},
      ["folders", folderId],
      {},
      blForceRefresh
    );
  }

  getImages(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ["images"],
      params,
      blForceRefresh
    );
  }

  getMediaList(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      [],
      params,
      blForceRefresh
    );
  }

  getMediaMetaData(
    mediaId: number,
    options: any = {},
    blForceRefresh: boolean = false
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", mediaId, "metadata"],
      {},
      blForceRefresh
    );
  }

  getQueue(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "queue"],
      {},
      blForceRefresh
    );
  }

  getQueueItemByUid(
    queueItemId: number,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "queue", queueItemId],
      {},
      blForceRefresh
    );
  }

  getVideos(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ["videos"],
      params,
      blForceRefresh
    );
  }

  importFromUrl(url: string, params: any = {}) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        url: url,
      },
      ["importFromUrl"],
      params
    );
  }

  importFromWebsite(url: string, params: any = {}) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        url: url,
      },
      ['media', "importFromWebsite"],
      params
    );
  }

  move(media: mediaItem | mediaItem[], options: any = {}) {
    return new Promise(async (resolve, reject) => {
      this.chooseFolder()
        .then((chooseResponse: chooseResponse) => {
          let target: mediaFolder =
            chooseResponse && chooseResponse.data && chooseResponse.data.item
              ? chooseResponse.data.item
              : [];

          if (!!target && !!target.uid) {
            if (!!(media as mediaItem).uid) {
              this.executeMove((media as mediaItem).uid, target.uid)
                .then(resolve)
                .catch(reject);
            } else if (!!media && !!(media as mediaItem[]).length) {
              (media as mediaItem[]).forEach(
                (mediaItem: mediaItem, index: number) => {
                  this.executeMove((mediaItem as mediaItem).uid, target.uid)
                    .then((response: any) => {
                      if (index === (media as mediaItem[]).length - 1) {
                        resolve(response);
                      }
                    })
                    .catch((error: any) => {
                      console.warn("> single item move failed", error);

                      if (index === (media as mediaItem[]).length - 1) {
                        resolve({
                          success: true,
                        });
                      }
                    });
                }
              );
            }
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  async onCapturePhoto(fileURI: string) {
    return new Promise(async (resolve, reject) => {
      let loading = await this.loadingCtrl.create({
        spinner: "circular",
      });
      loading.present();

      this.upload(fileURI)
        .then((response: any) => {
          loading.dismiss();

          if (
            response.link &&
            response.link.thumbnails &&
            response.link.thumbnails.md
          ) {
            let image =
              response.link.thumbnails.md.src ||
              response.link.thumbnails.lg.src;
            if (image[0] === "/") {
              image = hostUrl + "/" + image;
            }
            resolve(image);
          } else {
            reject(response);
          }
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  record(media: mediaQueueItem) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        media: media,
      },
      ["media", "create"]
    );
  }

  removeImage(image: string, type: string = "photo") {
    return new Promise((resolve, reject) => {
      let user = this.UserService.getUser() || {};
      switch (type) {
        case "cover":
          user.classifications.cover = "";
          user.classifications.coverImage = "";
          this.UserService.setUser(user, true).then(resolve).catch(reject);
          break;
        case "photo":
          user.photo = "";
          this.UserService.setUser(user, true).then(resolve).catch(reject);
          break;
      }
    });
  }

  screenshot(options: any = {}) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        options: options,
      },
      ["media", "screenshot"]
    );
  }

  updateQueueItem(item: mediaQueueItem) {
    item = JSON.parse(JSON.stringify(item));
    
    delete item.templateView;

    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        item: item,
      },
      ["media", "queue", "update"]
    );
  }

  upload(fileURI: string) {
    /*
    if(this.tools.isWeb()) {
      return this.uploadUsingWeb(fileURI);
    }
    */

    return new Promise((resolve, reject) => {
      let d = new Date(),
        apiCredentials = this.UserService.getUser(true);

      let user = {
        email: apiCredentials.email,
        password: apiCredentials.password,
      };

      let params: any = this.AppCMS.getRequestParams();

      let options: FileUploadOptions = {
        fileKey: "file",
        fileName:
          this.UserService.getUid() + "-" + d.getMilliseconds() + ".jpg",
        mimeType: "image/jpeg",
        httpMethod: "POST",
        chunkedMode: false,
        headers: {
          "Content-Type": undefined,
        },
        params: Object.assign(params, {
          user: user,
        }),
      };
      let url = this.AppCMS.getApiUrl() + "/mediaextend/upload.json";
      let r: any;

      const fileTransfer: FileTransferObject = this.fileTransfer.create();
      fileTransfer
        .upload(encodeURI(fileURI), encodeURI(url), options)
        .then((_r: any) => {
          r = _r;
          this.clearCache();
          let response = JSON.parse(r.response);
          if (response.status === false) {
            reject(
              response.message || "Ein unbekannter Fehler ist aufgetreten"
            );
          } else {
            resolve(response);
          }
        })
        .catch((e) => {
          console.log("> e", e);
          this.clearCache();
          reject(e);
        });
    });
  }

  async uploadUsingWeb(
    fileURI: string,
    files: any[] = null,
    index: number = null,
    params: any = {}
  ) {
    return new Promise((resolve, reject) => {
      if (index === null) {
        this.uploadUsingWeb(fileURI, files, 0, params)
          .then(resolve)
          .catch(reject);
      } else {
        let file_data = (files[index] ? files[index] : null),
            form_data = new FormData(),
            user: user = this.UserService.getUser(),
            globalParams: any = this.AppCMS.getRequestParams(),
            paramsKeys: string[] = Object.keys(globalParams || []);

        paramsKeys.forEach((key: string) => {
          if(!!globalParams[key]) {
            form_data.append(key, `${globalParams[key]}`);
          }
        });

        form_data.append("file", file_data);
        form_data.append("user[email]", user.email);
        form_data.append("user[password]", user.password);
        form_data.append("user[uid]", `${user.uid}`);
        form_data.append("userId", `${user.uid}`);

        if (!!params.folder_uid) {
          form_data.append("folder", `${params.folder_uid}`);
        }
        
        $.ajax({
          url: this.AppCMS.getApiUrl() + "/mediaextend/upload.json",
          dataType: "json",
          cache: false,
          contentType: false,
          processData: false,
          data: form_data,
          type: "post",
          success: (response: any) => {
            console.log('success response', response);

            files[index].response = response;

            if(!response.link && !!response.uploads && !!response.uploads[0] && !!response.uploads[0].link) {
              response.link = response.uploads[0].link;
            }

            if (!!response && !!response.link && !!response.link.thumbnail) {
              files[index].url = response.link.thumbnail;
            }
            
            this.events.publish("web:uploader:files:updated", files);

            if (index === files.length - 1) {
              resolve(response && response.success ? response.link : response);
            } else {
              this.uploadUsingWeb(fileURI, files, index + 1, params)
                .then(resolve)
                .catch(reject);
            }
          },
          error: (error: any) => {
            files[index].error = true;
            console.warn("error", error);

            if (index === null || !files[index + 1]) {
              reject(error);
            } else {
              this.uploadUsingWeb(fileURI, files, index + 1, params)
                .then(resolve)
                .catch(reject);
            }
          },
        });
      }
    });
  }

}