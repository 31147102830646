<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border">
    <div class="post-toolbar-background" [style.top]="view.header.top + 'px'" [style.opacity]="view.headerOpacity">
    </div>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="white" fill="clear" icon-only>
        <ion-icon name="close-outline" color="white"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="post.name" [style.top]="view.header.top + 'px'" [style.opacity]="view.headerOpacity">
    </ion-title>
    <ion-buttons slot="end" [hidden]="view.isPreviewMode">
      <ion-button (click)="toggleCollection()" color="white" fill="clear" icon-only>
        <ion-icon [name]="post.added ? 'bookmark' : 'bookmark-outline'" color="white"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [class.vip]="post.vip" [class.videoMode]="view.mode === 'video'" scrollEvents="true"
  (ionScroll)="onScroll($event)" (ionScrollEnd)="onScrollEnd()" fullscreen="true">
  
  <ion-fab id="adminBadge" *ngIf="view.admin" [hidden]="view.isPreviewMode" slot="fixed">
    <ion-fab-button fill="clear" color="white" size="small">
      <ion-icon name="lock-closed-outline" color="white"></ion-icon>
    </ion-fab-button>
    <ion-fab-list>
      <ion-fab-button (click)="accept()" icon-only color="success">
        <ion-icon name="checkmark-outline" color="white"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="reject()" icon-only color="danger">
        <ion-icon name="hand-left-outline" color="white"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="editPost()" icon-only color="primary">
        <ion-icon name="create-outline" color="white"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>

  <div #headlineToolbar class="headlineToolbar" [class.videoMode]="view.mode === 'video'">
    
    <ion-thumbnail *ngIf="!!post.thumbnail">
      <ion-img [src]="post.thumbnail" (ionError)="thumbnailLoadingFailed(post)"></ion-img>
    </ion-thumbnail>
    
    <ion-skeleton-text class="image" *ngIf="!post.thumbnail" [animated]="!post.uid"></ion-skeleton-text>
    
    <header class="headlineToolbarContainer">
      <h1 size="large" class="ion-text-wrap" [innerHTML]="post.name"></h1>

      <ion-item *ngIf="!!post.external || (!!post.hostLabel || !!post.host) || !!post.rating" #hostItem class="hostItem" lines="none" [class.hasAvatar]="!!post.avatar">

        <ion-avatar slot="start" class="avatarImg" *ngIf="!!post.avatar" (click)="openProfile()">
          <ion-img [src]="post.avatar"></ion-img>
        </ion-avatar>

        <ion-label color="white" *ngIf="!!appConfig.showPostCardHostLabel && (!!post.hostLabel || !!post.host)" (click)="openProfile()">
          <h2 [innerHTML]="post.hostLabel || post.host"></h2>
        </ion-label>

        <ion-icon name="shield-checkmark-outline" slot="end" color="white"></ion-icon>
        
        <ion-chip class="ratingBadge" *ngIf="post.rating && post.rating.score" slot="end" color="white">
          <ion-icon
            [name]="post.rating.score > 4.5 ? 'star' : post.rating.score > 2.5 ? 'star-half-outline' : 'star-outline'">
          </ion-icon>
          <ion-label [innerHTML]="post.rating.score"></ion-label>
        </ion-chip>

        <ion-button slot="end" icon-only fill="clear" (click)="information($event)">
          <ion-icon name="information-circle-outline" color="white"></ion-icon>
        </ion-button>

        <ion-button slot="end" [hidden]="!post.external || !post.url || !post.allowed" icon-only fill="clear" (click)="openExternal()">
          <ion-icon name="open-outline" color="white"></ion-icon>
        </ion-button>

      </ion-item>
    </header>
  </div>

  <ion-refresher slot="fixed" (ionRefresh)="dismiss($event)" *ngIf="user && user.uid">
    <ion-refresher-content pullingIcon="close"></ion-refresher-content>
  </ion-refresher>

  <div id="postPage" #postPage (swipe)="swipeEvent($event)" class="post-page post-page-size{{view.size}}"
    [class.vip]="post.vip" [class.videoMode]="view.mode === 'video'" [class.tts]="view.mode === 'tts'">
    
    <ion-item class="sponsoredPostItem" (click)="toggleSponsoredInfoView()" *ngIf="post.sponsored" color="primary"
      lines="none">
      <ion-icon name="cash-outline" slot="start"></ion-icon>
      <ion-label class="ion-text-wrap">
        <h4 class="ion-text-wrap" [innerHTML]="'post_sponsored_label'|translate"></h4>
        <small class="ion-text-wrap" [hidden]="view.showSponsoredInfo"
          [innerHTML]="'post_sponsored_subHeadline'|translate"></small>
        <small class="ion-text-wrap" [hidden]="!view.showSponsoredInfo"
          [innerHTML]="'post_sponsored_text'|translate"></small>
        <ion-button [hidden]="!view.showSponsoredInfo" (click)="rate()" color="white" fill="outline">
          <ion-icon name="flag-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'rate_sponsoredpost'|translate"></ion-label>
        </ion-button>
      </ion-label>
      <ion-icon [name]="view.showSponsoredInfo ? 'chevron-up-outline' : 'chevron-down-outline'" slot="end"></ion-icon>
    </ion-item>

    <div class="ion-padding">

      <pipeline-post-collection-card [(post)]="post" *ngIf="post.collection && post.collection.length"></pipeline-post-collection-card>
      <pipeline-post-reader [(post)]="post"></pipeline-post-reader>

      <div class="container">

        <pipeline-download-wall *ngIf="view.downloadWall"></pipeline-download-wall>

        <pipeline-post-view-short-card
          *ngIf="view && view.short && (view.postWebTemplate !== 'preview_register') && (post && !post.vip)">
        </pipeline-post-view-short-card>

        <pipeline-post-view-short-register-card
          *ngIf="view && view.short && (view.postWebTemplate === 'preview_register')">
        </pipeline-post-view-short-register-card>

        <pipeline-post-vip-card [(post)]="post" *ngIf="post.vip"></pipeline-post-vip-card>
        <pipeline-post-author-card [(post)]="post" *ngIf="appConfig.showPostAuthorCard && post && !!post.uid"></pipeline-post-author-card>

        <ion-card *ngIf="appConfig.useCommentsExtension" class="postCommentsCard">
          <pipeline-post-comments [(post)]="post" [config]="commentsConfig"></pipeline-post-comments>
        </ion-card>
        
        <pipeline-post-pagination [(post)]="post" *ngIf="appConfig.showPostPagination && posts && (posts.length > 1)"></pipeline-post-pagination>
        
        <pipeline-post-related-articles [(post)]="post" *ngIf="appConfig.showPostRelatedArticles && !!(post.relatedArticles && post.relatedArticles.length)">
        </pipeline-post-related-articles>

        <pipeline-no-entries-card *ngIf="post && (!post.post_content || (post.post_content && !post.post_content.length))"></pipeline-no-entries-card>

      </div>

    </div>

  </div>

</ion-content>

<pipeline-post-footer [content]="content" [(post)]="post" [(view)]="view"></pipeline-post-footer>