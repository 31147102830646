import { Component, OnInit, NgZone } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { ViewPage } from './pages/view/view.page';
import { InvisibleScreenPage } from './pages/account/invisible-screen/invisible-screen.page';
import { ProfilePage } from './pages/profile/profile/profile.page';
import { FeedbackPage } from './pages/feedback/feedback.page';

import { AppEventsService } from './services/app-events.service';
import { BrowserService } from './services/browser.service';
import { ToolsService } from './services/tools.service';
import { AbonnementService } from './services/abonnement.service';
import { MenuService } from './services/menu.service';
import { InboxService } from './services/inbox.service';
import { ThemesService } from './services/themes.service';
import { TestingService } from './services/testing.service';
import { NetworkService } from './services/network.service';
import { AdminService } from './services/admin.service';
import { ModalService } from './services/modal.service';
import { BasketService } from './services/basket.service';
import { EventsService } from './services/events.service';
import { LogfileService } from './services/logfile.service';
import { PushnotificationsService } from './services/pushnotifications.service';
import { SearchService } from './services/search.service';
import { ShortcutsService } from './services/shortcuts.service';
import { UserService } from './services/user.service';
import { AppcmsService } from './services/appcms.service';
import { TabsService } from './services/tabs.service';
import { IntroService } from './services/intro.service';
import { AccountsService } from './services/accounts.service';
import { ErrorService } from './services/error.service';
import { AlertService } from './services/alert.service';
import { BackgroundService } from './services/background.service';
import { AppleWatchService } from './services/apple-watch.service';
import { ConfigService } from './services/config.service';
import { LoginService } from './services/login.service';
import { ToastService } from './services/toast.service';

import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';

import { Shake } from '@awesome-cordova-plugins/shake/ngx';
import { ThreeDeeTouch, ThreeDeeTouchQuickAction } from '@awesome-cordova-plugins/three-dee-touch/ngx';

declare const IonicDeeplink: any;

import * as moment from 'moment';

//import { register } from 'swiper/element/bundle';
//register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  private handlers: any = {};

  public selectedIndex = 0;

  public appConfig: pipelineAppConfig;

  public appPages: appPage[];

  public avatarUrl: string = './assets/img/avatars/1.jpg';

  basketInfo: basketInfo = {};

  categories: category[];

  icons: any = {};
  
  inboxInterval: any;

  inboxIntervalTime: number;

  menuData: any = {
    visible: false,
  };

  search: searchOptions = {
    query: '',
  }

  urls: any = {
    career: 'https://pipeline.page/karriere/',
    help_center: 'https://pipeline.page/hilfe-center/',
    submit_blog: 'https://pipeline.page/blog-eintragen/',
  };

  user: user;

  view: any = {
    menu: {
      side: (window.innerWidth > 768 ? 'start' : 'end'),
    },
    toastsBlocked: false,
    webPages: [],
  };

  constructor(
    private abo: AbonnementService,
    private accounts: AccountsService,
    private admin: AdminService,
    private alert: AlertService,
    private AppCMS: AppcmsService,
    private appEvents: AppEventsService,
    private appleWatch: AppleWatchService,
    private background: BackgroundService,
    private basketService: BasketService,
    private browser: BrowserService,
    private configService: ConfigService,
    private errorHandler: ErrorService,
    private events: EventsService,
    private inbox: InboxService,
    private intro: IntroService,
    private log: LogfileService,
    private loginService: LoginService,
    private menu: MenuService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private network: NetworkService,
    private platform: Platform,
    private push: PushnotificationsService,
    private searchService: SearchService,
    private shake: Shake,
    private shortcuts: ShortcutsService,
    private tabs: TabsService,
    private testing: TestingService,
    private themes: ThemesService,
    private ThreeDeeTouch: ThreeDeeTouch,
    private translate: TranslateService,
    private toastService: ToastService,
    private tools: ToolsService,
    private zone: NgZone,
    public userService: UserService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.avatarUrl = this.userService.getAvatarUrl();
    this.menuData.visible = (!this.appConfig.showLoginPage || !!this.userService.getUid());
    this.user = this.userService.getUser() || {};

    if(this.appConfig.useAbonnements) {
      this.view.aboVersion = this.abo.getCurrentVersion();
    }

    this.view.isDesktop = this.tools.isDesktop();
    this.view.isWeb = this.tools.isWeb();

    this.configService.init();
  }
  
  abonnement() {
    this.navigateForward('/abonnement');
  }

  account() {
    this.navigateForward('/account', { animated: true, });
  }

  agb() {
    if(this.configService.isWhitelabel()) {
      this.browser.create(this.configService.getLink('terms_of_use'));
    } else {
      this.dynamic(1);
    }
  }

  appearance() {
    this.navigateForward('/appearance');
  }
  
  appearanceDisabled() {
    if(this.view.aboVersion === 'free') {
      this.navigateForward('/abonnement'); 
    }
  }

  applyLanguage(blRefreshHome: boolean = false) {
    let language = this.userService.getLanguage();
    
    this.translate.use(language);
    moment.locale(language);


    if(blRefreshHome && (this.user && this.user.uid)) {
      this.events.publish('home:refresh');
    }

  }

  basket() {
    this.modalService.closeAll();
    this.events.publish('view:basket');
    if(window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  career() {
    this.browser.create(this.urls.career);
    if(window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  async changeAccount() {
    this.menu.close();

    try {
      this.accounts.switch();
    } catch(e) {
      console.warn('accounts: switch error', e);
    }
  }

  async checkVisibility(user: user) {
    let blShouldShow = (
      user &&
      user.classifications &&
      user.classifications.introDone &&
      !user.classifications.public &&
      !user.classifications.forcePublicState &&
      (Math.random() < 0.2)
    );
    if(blShouldShow) {
      let visibilityModal = await this.modalService.create({
        component: InvisibleScreenPage,
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'visibilityModal'
      });
      await visibilityModal.present();
    }
  }

  dynamic(pageId: number) {
    this.modalService.closeAll();
    this.events.publish('view:page', pageId);
    if(window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  experiments() {
    this.navigateForward('/experiments');
  }

  feedback() {
    this.navigateForward('/feedback');
  }

  filters() {
    this.navigateForward('/word-list');
  }

  filtersDisabled() {
    if(this.view.aboVersion === 'free') {
      this.navigateForward('/abonnement');
    }
  }

  handleSubMenu(page: appPage, event: any = null) {
    page.active = !page.active;

    if(!!event) {
      event.preventDefault();
    }

    this.view.blockMenuToggle = true;
    setTimeout(() => { this.view.blockMenuToggle = false }, 200);

    return false;
  }

  helpCenter() {
    this.browser.create(this.urls.help_center);
    if(window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  imprint() {
    if(this.configService.isWhitelabel()) {
      this.browser.create(this.configService.getLink('imprint'));
    } else {
      this.dynamic(3);
    }
  }

  initAppleWatch() {
    if(this.appConfig.useAppleWatchExtension) {
      let appId: string = this.configService.getAppId();
      if(!!appId) {
        this.appleWatch.init(`group.${appId}`);
      }
    }
  }

  initAppPages() {
    
    /*
    this.appPages = [
      {
        key: 'settings',
        url: '/tabs/settings',
        icon: 'settings-outline',
        hideIfLoggedOut: true,
      },
    ];
    */

    this.appPages = (!!this.appConfig.menuPages ? this.appConfig.menuPages : []);

    if(this.appConfig.useInbox) {
      this.appPages.push({
        key: 'inbox',
        badge: this.inbox.getBadge(),
        url: '/tabs/inbox',
        icon: 'file-tray-outline',
        handler: () => {
          this.events.publish("tab:updated", {uid: 'inbox'});
        },
        hideIfLoggedOut: true,
      });
    }

    if(this.appConfig.useInviteFriends) {
      this.appPages.push({
        key: 'invite_friends',
        url: '/tabs/invite-friend',
        icon: 'mail-outline',
        handler: () => {
          this.events.publish("tab:updated", {uid: 'invite_friends'});
        },
        hideIfLoggedOut: true,
      });
    }

  }

  initAppVersion() {
    this.view.appVersion = this.configService.getAppVersion();
  }

  initBackground() {
    this.background.init();
  }

  async initBasket() {
    if(this.appConfig.useShop) {
      this.basketInfo = await this.basketService.calculateBasketInfo();

      this.events.subscribe('basketInfo:updated', async (basketInfo: basketInfo) => {
        this.basketInfo = basketInfo;
      });
    }
  }

  initHandlers() {
    this.handlers.search = () => {
      this.events.publish('view:search');
    };
  }

  initializeApp() {
    this.platform.ready()
    .then(() => {
      moment.locale('de');
      
      if(!this.tools.isWeb()) {
        
        try {
          StatusBar.setOverlaysWebView({ overlay: false });
          //StatusBar.setStyle({ style: Style.Dark });
        } catch(e) {
          console.warn('statusBar e', e);
        }

        try {
          SplashScreen.hide();
        } catch(e) {
          console.warn('splashscreen e', e);
        }
        
        try {
          Keyboard.setAccessoryBarVisible({isVisible: true});
        } catch(e) {
          console.warn('keyboard error', e);
        }
        
      }

      this.alert.init();
      this.errorHandler.init();
      this.network.init();
      
      this.initLanguage();
      this.initAppVersion();
      this.initEvents();
      this.initThemes();

      if(!this.tools.isWeb()) {
        this.initAppleWatch();
        this.initBackButton();
        this.initBackground();
      }

      /*
      try {
        delete window['TapticEngine'];
        delete Plugins.Haptics;
      } catch(e) {
        console.warn('delete haptics e', e);
      }
      */
      
      setTimeout(() => {
        this.initBasket();
        this.initGlobalFeedback();
        
        if(!this.tools.isWeb()) {
          this.initShortcuts();
          this.initDeepLinks();
          this.initThreeDeeTouch();
        }
        
      }, 1500);
    });
  }

  initBackButton() {
    try {
      App.addListener('backButton', ({canGoBack}) => {
        let blIsModal = this.modalService.isModal();
        if(!!blIsModal) {
          this.modalService.close();
        } else
        if(!canGoBack){
          App.exitApp();
        } else {
          window.history.back();
        }
      });
    } catch(e) {
      console.warn('back button error', e);
    }
  }

  initDeepLinks() {

    try {
      IonicDeeplink.route(
        {
          //'/code/verify/:codeId': CodeVerifyPage,
          '/tabs/profile/:profileId': ProfilePage,
          '/view/:postId': ViewPage,
        },
        (match: any) => {
          //console.log('Successfully matched route', match);
        },
        (nomatch: any) => {
          console.error('Got a deeplink that didn\'t match', nomatch);
        },
      );
    } catch(e) {
      console.warn('deeplinks error', e);
    }

    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        let url = (data.url || '').replace('https://', '').replace('open.', '').replace('web.', '').replace('pipeline.page', '').replace('/', ''),
            split = url.split('/');
        
        switch(split[0]) {
          case 'code':
            if(split[1]) {
              switch(split[1]) {
                case 'verify':
                  //console.log('verify code', split);
                  break;
                default:
                  //console.log('default code', split);
                  break;
              }
            }
            break;
          case 'profile':
            if(split[1] && split[1].length) {
              this.events.publish('view:profile', parseInt(split[1]));
            }
            break;
          case 'view':
            if(split[1] && split[1].length) {
              this.events.publish('view:post', parseInt(split[1]));
            }
            break;
          default:
            if(split[0] && split[0].length) {
              this.events.publish('view:post', parseInt(split[0]));
            }
            break;
        }
      });

    });

  }

  initEvents() {
    this.appEvents.init();

    this.events.subscribe('config:updated', (appConfig: pipelineAppConfig) => {
      this.appConfig = appConfig;
      this.initAppPages();
      this.initWebPages();
    });

    this.events.subscribe('login:show', () => {
      this.loginService.show();
    });

    this.events.subscribe("menu:hide", () => {
      this.zone.run(() => {
        this.menuData.visible = false;
      });
    });

    this.events.subscribe("menu:show", () => {
      this.zone.run(() => {
        this.menuData.visible = true;
      });
    });

    this.events.subscribe("appcms:user:loggedout", () => {
      this.accounts.setMultiMode(false);
      this.user = this.userService.getUser() || {};
      this.log.loggedOut();

      if(this.appConfig.useInbox && this.inboxInterval) {
        clearInterval(this.inboxInterval);
      }
      
    });

    this.events.subscribe("appcms:user:loggedin", () => {
      this.user = this.userService.getUser() || {};
      this.AppCMS.storeCurrentAuthDetails(this.user);
      this.menu.enable();
    });

    this.events.subscribe("appcms:user:updated", () => {
      this.avatarUrl = this.userService.getAvatarUrl();
      this.user = this.userService.getUser() || {};
      this.menuData.visible = (!this.appConfig.showLoginPage || !!this.userService.getUid());
      
      this.applyLanguage(false);

      if(this.user && this.user.uid && (this.user.uid !== -1)) {
        this.AppCMS.storeCurrentAuthDetails(this.user);
        this.menu.enable();
        this.intro.loadedSliderPage(true);

        this.checkVisibility(this.user);
        this.initInbox();

        if(this.userService.isType('Admin')) {
          this.admin.init();
        }

        try {
          this.initPushNotifications();
          this.push.sendTag('type', this.userService.getType());
        } catch(e) {
          console.warn('updating tag error', e);
        }

      }

    });

    this.events.subscribe('categories:visible:set', (categories: category[]) => {
      this.categories = this.categories || categories;
    });

    this.toastService.init();

    this.events.subscribe("view:category", (data: any) => {
      let route = (this.navCtrl as any).router.url;
      if(route !== '/tabs/home') {
        this.navCtrl
        .navigateRoot(this.configService.getRoute('home'))
        .then(() => {
          this.events.publish('home:view:category', data);
        });
      } else {
        this.events.publish('home:view:category', data);
      }
    });
    
  }

  initGlobalFeedback() {
    this.shake.startWatch(60).subscribe(async () => {
      let feedbackModal = await this.modalService.create({
        component: FeedbackPage,
        componentProps: {},
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'feedbackModal'
      });
      await feedbackModal.present();
    });
  }

  initInbox() {
    if(this.appConfig.useInbox) {
      setTimeout(() => {

        if(this.user && this.user.uid && (this.user.uid !== -1)) {
          this.startInboxInterval();
        }
  
        this.events.subscribe('inbox:interval:update', (time: number) => {
          this.inboxIntervalTime = time;
          this.startInboxInterval();
        });
        
      }, (15 * 1000));
    }
  }

  initLanguage() {
    try {
      this.translate.setDefaultLang('en');
    
      let userLanguage = this.user && this.user.classifications ? this.user.classifications.language : null;
      let language = userLanguage || (window.navigator.language || 'en').split('-')[0];
      
      if(language) {
        this.translate.use(language);
        moment.locale(language);
      }
    } catch(e) {
      console.warn('language error', e);
    }
  }

  initPushNotifications() {
    if(this.appConfig.usePushNotifications) {
      try {
        this.push.init();
      } catch(e) {
        console.warn('push init error', e);
      }
    }
  }

  initShortcuts() {
    if(this.appConfig.useShortcuts) {
      this.shortcuts.init();
    }
  }

  initTesting() {
    if(this.appConfig.useTesting) {
      this.testing.init();
    }
  }

  initThemes() {
    if(this.appConfig.useThemes) {
      this.themes.init();
    }
  }
  
  initThreeDeeTouch() {
    if(this.appConfig.useThreeDeeTouch) {
      this.ThreeDeeTouch.isAvailable()
      .then(() => {
        let actions: ThreeDeeTouchQuickAction[] = [
          {
            type: 'home',
            title: 'Home',
            iconType: 'Home'
          },
          {
            type: 'checkin',
            title: 'Gespeichert',
            iconType: 'Compose'
          },
          {
            type: 'people',
            title: 'Personen',
            iconType: 'People'
          },
          {
            type: 'search',
            title: 'Suchen',
            iconType: 'Search'
          },
        ];
        this.ThreeDeeTouch.configureQuickActions(actions);
        this.ThreeDeeTouch.onHomeIconPressed().subscribe(
        (payload) => {
          //console.log('Pressed the ${payload.title} button');
          //console.log(payload.type);
        }
        )
      })
      .catch((error: any) => {
        console.warn('ThreeDeeTouch', error);
      });
    }
  }

  initWebPages() {
    setTimeout(async () => {
      this.view.webPages = await this.tabs.getChecked();

      if(!!this.view.webPages) {
        this.view.webPages = this.parseWebPages(this.view.webPages);
      }

      this.events.subscribe('tabs:updated', async () => {
        this.view.webPages = await this.tabs.getChecked(true);
        if(!!this.view.webPages) {
          this.view.webPages = this.parseWebPages(this.view.webPages);
        }
      });
    });
  }

  interests() {
    this.navigateForward('/interests');
  }
  
  me() {
    if(!this.user.uid || (this.user.uid === -1)) {
      return this.changeAccount();
    } else {
      this.navigateForward(`/tabs/profile/${this.user.uid}`);
      this.events.publish("tab:updated", {
        uid: 'profile',
      });
    }
  }

  navigateForward(route: string, options: any = null) {
    options = options || {};
    this.navCtrl.navigateForward(route, options);
    if(window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  ngOnInit() {
    this.AppCMS.storeCurrentAuthDetails(this.user);

    this.applyLanguage(false);
    
    this.initAppPages();
    this.initTesting();
    this.initHandlers();
    this.initializeApp();
    this.initWebPages();

    this.view.isDesktop = this.tools.isDesktop();
    this.view.isWeb = this.tools.isWeb();

    this.view.menu.side = (window.innerWidth > 768 ? this.appConfig.menuSideDesktop : this.appConfig.menuSideMobile);

    this.events.subscribe("appcms:user:updated", () => {
      if(this.appConfig.useAbonnements) {
        this.view.aboVersion = this.abo.getCurrentVersion();
      }
    });

    window.addEventListener('resize', () => {
      this.view.isDesktop = this.tools.isDesktop();
      this.view.isWeb = this.tools.isWeb();

      this.view.menu.side = (window.innerWidth > 768 ? this.appConfig.menuSideDesktop : this.appConfig.menuSideMobile);

      if((window.innerWidth >= 768) && !!this.userService.getUid()) {
        this.menu.enable();
      }
    });

    //this.menu.swipeGesture.(false, `menuId`);
  }
  
  onAppPageClick(page: appPage, i: number, event: any = null) {

    if(!!page.disabled) {
      return false;
    }
    
    page.handler = page.handler || this.tabs.getHandler(page.uid);

    if(!page.hasOwnProperty('handler') && this.handlers.hasOwnProperty(page.uid)) {
      page.handler = this.handlers[page.uid];
    }

    if(page.children && page.children.length) {
      this.handleSubMenu(page, event);
    } else
    if(page.handler) {
      page.handler(i);
    } else {
      this.selectedIndex = i;
    }
  }

  onWebPageClick(page: any, i: number, event: any = null) {
    return this.onAppPageClick(page, i, event);
  }

  onMenuToggleClick(p: appPage) {

    if(!!this.view.blockMenuToggle) {
      return false;
    }

    this.modalService.closeAll();

    if((!p.children || !p.children.length) || (!!p.children && p.active)) {
      this.menu.close();
    }
  }

  parseWebPages(webPages: tab[]) {
    webPages = JSON.parse(JSON.stringify(webPages));

    if(webPages && webPages.length) {
      webPages.forEach((webPage: tab) => {
        if(webPage && webPage.route && webPage.route.indexOf('/tabs/') !== 0) {
          let seperator: string = webPage.route[0] === '/' ? '' : '/';
          webPage.route = `/tabs${seperator}${webPage.route}`;
        }
      });
    }

    return webPages;
  }

  postsAdmin() {
    this.navigateForward('/posts-admin');
  }

  privacyPolicy() {
    if(this.configService.isWhitelabel()) {
      this.browser.create(this.configService.getLink('privacy_policy'));
    } else {
      this.dynamic(2);
    }
  }

  refreshInbox() {
    this.inbox.refresh()
    .catch((error) => {
      if(!!error && (error !== 'Messages_no_messages')) {
        console.warn('inbox error', error);
      }
    });
  }

  runSearch(event: any = null) {
    this.navCtrl.navigateRoot('/looma-search');

    if(!this.search.query || (this.search.query.length < 3)) {
      console.warn('> input query too short');
      return false;
    }

    setTimeout(() => {
      this.events.publish('search:global:loading:updated', true);
      this.events.publish('search:global:query:updated', this.search.query);
    }, 150);

    this.searchService.run(this.search)
    .then((response: any) => {
      console.log('> response', response);
      this.events.publish('search:global:loading:updated', false);
      this.events.publish('search:global:response:updated', response);
    })
    .catch((error: any) => {
      this.events.publish('search:global:loading:updated', false);
      this.events.publish('error', error);
    });
  }

  settings() {
    this.navigateForward('/tabs/settings');
  }

  startInboxInterval() {
    let time = this.inboxIntervalTime || ((60 * 1000) * 2);

    if(this.inboxInterval) {
      clearInterval(this.inboxInterval);
    }

    if(this.user && this.user.uid && (this.user.uid !== -1)) {
      this.refreshInbox();
      this.inboxInterval = setInterval(() => {
        this.refreshInbox();
      }, time);
    }
  }
  
  submitBlog() {
    this.browser.create(this.urls.submit_blog);
    if(window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  wallet() {
    this.navigateForward('/tabs/wallet');
  }

}