import { Injectable } from '@angular/core';

import { ModalService } from './modal.service';

import { ChooseActionSheetPage } from '../pages/chooser/choose-action-sheet/choose-action-sheet.page';

@Injectable({
  providedIn: 'root'
})
export class ChooserService {

  constructor(
    private modalCtrl: ModalService,
  ) {
  }

  choose(chooseConfig: chooseConfig) {
    return new Promise(async (resolve, reject) => {
      this.modalCtrl.setIsModal(true);
      
      let chooseModal = await this.modalCtrl.create({
        component: ChooseActionSheetPage,
        componentProps: chooseConfig,
        animated: true,
        presentingElement: await this.modalCtrl.getTop(),
        cssClass: 'defaultModal'
      });
      
      chooseModal.onWillDismiss().then((response: any) => {
        this.modalCtrl.setIsModal(false);
        resolve(response);
      });

      chooseModal.present();
    });
  }

}
