import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ConfigService } from '../../../services/config.service';
import { EventsService } from '../../../services/events.service';
import { ModalService } from '../../../services/modal.service';
import { ReactionsService } from '../../../services/reactions.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-post-comments',
  templateUrl: './post-comments.page.html',
  styleUrls: ['./post-comments.page.scss'],
})
export class PostCommentsPage implements OnInit {

  commentsService: any;

  config: pipelineAppConfig;

  post: post;

  user: user;

  view: any = {
    input: '',
    placeholders: [{},{},{},{},{},{},{},{},{},{},{},{}],
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private navParams: NavParams,
    private reactions: ReactionsService,
    private userService: UserService,
  ) {
    this.commentsService = this.navParams.get('commentsService');
    this.config = this.configService.getConfig();
    this.post = this.navParams.get('post');
    this.user = this.userService.getUser() || {};

    if(!!this.config.useReactions) {
      this.view.emojis = this.reactions.getEmojis();
    }
  }

  dismiss() {
    this.modalService.dismiss();
  }

  doRefresh() {
      
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};

    this.onCommentsLoaded();
  }

  onCommentsLoaded() {
    this.events.publish('post:render');
  }
  
  onReactionsSegmentChanged() {
    
  }

  submitComment() {

    let comment = {
      description: this.view.input,
      item: this.post.uid,
      user: this.userService.getUid(),
    };

    this.commentsService.submit(comment)
    .then(() => {
      this.view.input = '';
      this.doRefresh();
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

}
