export var hostUrl: string = 'https://app.pipeline.page';

export var pipelineAppConfig: pipelineAppConfig = {
    allowUserCreateChat: true,
    allowUserCreateChatGroup: true,
    allowUserEditPostSettings: false,
    allowUserForgotPassword: true,
    allowUserLogin: true,
    allowUserPostContent: false,
    allowUserPostContentAsCreator: true,
    allowUserPostContentAsGuest: false,
    allowUserPostContentAsUser: false,
    allowUserRegister: true,
    allowUserTranslatePost: false,
    allowUserWriteChatMessage: true,
    appDescription: 'Die offizielle Pure Energy App',
    appId: 'page.pipeline.pureenergy',
    appName: 'Pure Energy',
    appPackageName: 'pureenergy-pipeline-app',
    appSlogan: 'Die offizielle Pure Energy App',
    appVersionId: 100940,
    appVersionName: '0.9.40',
    contentHostUrl: 'https://www.pure-drink.com',
    contentHostUrlLike: true,
    environments: {
        api: {
            production: 'https://app.pipeline.page/api',
        },
        app: {
            production: 'https://app.pipeline.page',
        }
    },
    filterDiscoverPostsByContentHostUrl: true,
    filterHomePostsByContentHostUrl: true,
    filterLocalPostsByContentHostUrl: true,
    filterPeopleByContentHostUrl: true,
    filterSearchResultsByContentHostUrl: true,
    filterUserByTeamUids: false,
    footerBottomText: `<p>
        Erstellt mit <a href="https://pipeline.page" target="_blank">pipeline</a><br>
        &copy; ${new Date().getFullYear()} pipeline App GmbH
    </p>`,
    includeRatingsInFeed: true,
    includeReactionsInFeed: true,
    includeRelatedArticlesInFeed: false,
    isWhitelabel: true,
    links: {
        imprint: '/impressum',
        privacy_policy: '/datenschutzerklaerung/',
        terms_of_use: '/allgemeine-geschaeftsbedingungen/',
    },
    loginHeadlineText: 'Die offizielle Pure Energy App',
    loginWebLeftAreaBackgroundColor: '#be0113',
    maxPostAge: 3650,
    menuPages: [
    ],
    menuSideDesktop: 'start',
    menuSideMobile: 'start',
    multiLanguageSupport: true,
    multiUser: false,
    orderBy: {
        discover: 'auto',
        home: 'auto',
        local: 'auto',
        people: 'auto',
        shop: 'auto',
        showroom: 'auto',
    },
    postsVipFactor: 0,
    pushAppId: 'bf2596b7-6dd1-4561-8e69-3ef29a86d4ad',
    routes: {
        discover: '/tabs/discover',
        home: '/tabs/feed/36',
        local: '/tabs/local',
        people: '/tabs/people',
        shop: '/tabs/shop/host/3366',
    },
    showAccountBecomeCreator: false,
    showAccountClearCaches: false,
    showAccountSecurity: true,
    showAppearanceColorSchemes: true,
    showAppearanceFontSizeSelector: false,
    showAppearanceFontsSelector: false,
    showAppearancePreferedImageSize: false,
    showAppearanceTabs: false,
    showCheckoutPage: true,
    showFeedBottomShadow: true,
    showFooterAppVersion: false,
    showFooterBottomText: true,
    showHeaderLargeTitle: false,
    showHeaderMenuButton: true,
    showHeaderToolbarLogo: true,
    showHeaderToolbarLogoInApp: true,
    showHeaderToolbarLogoInWeb: true,
    showHeaderToolbarTitle: false,
    showHeaderToolbarTitleInApp: false,
    showHeaderToolbarTitleInWeb: false,
    showJobPostsInFeeds: false,
    showJobPostsInSearchResults: false,
    showLocalSourcesSuggestions: false,
    showLoginPage: false,
    showLoginTopLogo: true,
    showMenuGlobalSearch: false,
    showMenuIfLoggedOut: true,
    showMenuHeaderLogo: false,
    showMenuHeaderTitle: false,
    showMenuPagesInApp: true,
    showMenuPagesInWeb: true,
    showPodcastsSuggestions: true,
    showPodcastsSuggestionsInFeed: true,
    showPostCardAdminFab: false,
    showPostAuthorCard: false,
    showPostCardExcerpt: true,
    showPostCardHostLabel: false,
    showPostCardOptions: false,
    showPostCardVerifiedBadges: false,
    showPostPagination: false,
    showPostReadInAppCard: true,
    showPostRelatedArticles: true,
    showProfileDisplayName: true,
    showProfileEvents: false,
    showProfileFriends: false,
    showProfileSocialInformation: true,
    showProfileSocialInformationLabels: true,
    showProfileUserGroup: false,
    showProfileUserGroupAsPrimaryLabel: false,
    showProfileUsername: false,
    showSalut: true,
    showSettingsLegalLinks: true,
    showSettingsLinks: false,
    showShopCategories: true,
    showShopNewestProducts: true,
    showShopRelatedProductsOnDetailPage: true,
    showShopSaleProducts: true,
    showShopSources: true,
    showShopSuggestedProducts: true,
    showShopTrendingProducts: true,
    showSuggestedProfilesOnFeedPage: false,
    showSuggestedProfilesOnHomePage: false,
    showSuggestedProfilesOnLocalPage: false,
    useSharingExtension: true,
    useShortcuts: false,
    useSimplyLocalExtension: false,
    showTabBar: true,
    showTabBarAsDock: true,
    showTabBarInMenuOnApp: true,
    showTabBarInMenuOnWeb: true,
    showTabBarLabels: true,
    showTabMenu: false,
    tabBarActiveStyle: 'solid',
    tabs: [
        {
            uid: 'home',
            checked: true,
            icon: 'home-outline',
            indent: 'home',
            index: 1,
            name: 'home',
            route: '/tabs/feed/36',
            url: '/tabs/feed/36',
        },
        {
            uid: 'shop',
            checked: true,
            icon: 'storefront-outline',
            indent: 'shop',
            index: 5,
            name: 'shop',
            route: '/tabs/shop/host/3364',
            url: '/tabs/shop/host/3364',
        },
        {
            uid: 'tv',
            checked: true,
            icon: 'tv-outline',
            indent: 'tv',
            index: 2,
            name: 'tv',
            route: '/tabs/tv',
            url: '/tabs/tv',
        },
        {
            uid: 'events_list',
            checked: true,
            icon: 'calendar-number-outline',
            indent: 'events_list',
            index: 1,
            name: 'calendar',
            route: '/tabs/events-list',
            url: '/tabs/events-list',
        },
    ],
    translucentFooters: true,
    translucentHeaders: true,
    useAbonnements: false,
    useAdsExtension: true,
    useAiExtension: true,
    useAppearance: true,
    useAppleWatchExtension: false,
    useArchive: false,
    useAssetsExtension: true,
    useAvatars: false,
    useAuthExtension: false,
    useBionicReadingExtension: false,
    useBlogsExtension: true,
    useCalendar: true,
    useCategories: false,
    useCategoriesOnDiscoverPage: true,
    useCategoriesOnFeedPages: true,
    useCategoriesOnHomePage: true,
    useCategoriesOnLocalPage: false,
    useCategoriesOnSearchPage: true,
    useChatExtension: true,
    useCommentsExtension: true,
    useCompaniesExtension: false,
    useComplexityExtension: false,
    useCookiesAlertInApp: true,
    useCookiesAlertInWebApp: true,
    useCouponsExtension: true,
    useDatingExtension: false,
    useDeathAnnouncementsExtension: false,
    useDiscover: false,
    useDocumentsExtension: false,
    useEventsList: true,
    useExperiments: false,
    useFeedback: false,
    useFeeds: false,
    useFiltersExtension: false,
    useFollow: false,
    useGoogleMapsExtension: true,
    useGroups: true,
    useHeadlineProfileAvatar: true,
    useHome: true,
    useInbox: true,
    useInterests: false,
    useIntro: false,
    useIntroCards: true,
    useIntroPeople: false,
    useIntroReadingSettings: false,
    useInviteFriends: false,
    useJobPosts: false,
    useJobTitlesExtension: false,
    useLocal: false,
    useLoomaExtension: false,
    useMarketplaceExtension: false,
    useMediaExtension: true,
    useMoviesExtension: false,
    useOrderByFilters: true,
    usePayPalExtension: true,
    usePeople: false,
    usePointsSystem: true,
    usePostsAdmin: false,
    usePostPaywallExtension: false,
    useProfile: true,
    useProfileDisplayNames: true,
    useProfileTitleExtension: false,
    useProfileUsernames: false,
    usePushNotifications: true,
    usePushNotificationsChannels: false,
    useReactions: true,
    useRegionSearch: true,
    useRegionSearchSuggestions: false,
    useReportingExtension: false,
    useSearch: false,
    useSettings: true,
    useShop: true,
    useShopAccount: true,
    useShopCategories: true,
    useShopInternalPaymentProcess: true,
    useShowroom: false,
    useSignInWithApple: false,
    useSignInWithFacebook: false,
    useSignInWithGoogle: false,
    useStories: true,
    useStoriesCameraExtension: true,
    useStoriesOnDiscoverPage: true,
    useStoriesOnHomePage: false,
    useStoriesOnLocalPage: false,
    useStoriesOnPeoplePage: true,
    useTeamsExtension: false,
    useTesting: false,
    useThemes: true,
    useThreeDeeTouch: false,
    useTransparencyExtension: false,
    useTvExtension: true,
    useTvReelsUI: true,
    useTvVideosFromBlogPosts: true,
    useTvVideosFromMediaextend: true,
    useVideosExtension: true,
    useWallet: true,
    useWeclappExtension: false,
    useWeloveWalletExtension: true,
};